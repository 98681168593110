$rootPath: "/v40/22519";
// Override variables in the default theme
$brand-primary: #23b7e5;
$brand-info: #23b7e5;
$input-border-focus: red;

@import "Theme";

.btn-primary {
    background-color: $brand-primary;

    &:active, &:hover, &:focus {
        background-color: darken($brand-primary, 10%);
        border-color: darken($brand-primary, 12%);
    }
}
